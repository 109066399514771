<template>
    <modal name="linkIntegracaoEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
        <CloseModal :close="close"/>
        <div class="modalbox">
            <div class="modalbox-content">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab title="Editar Link Integrado">
                        <div class="xrow">
                            <div class="col-2-3">
                                <div class="form-group">
                                    <label for="">Descrição:</label>
                                    <input class="form-control" maxlength="250" v-model="item.descricao"/>
                                    <div class="message">{{ validation.firstError("item.descricao") }}</div>
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label for="">Tipo:</label>
                                    <select class="form-control" v-model="item.tipo"
                                            :disabled="(item.tipo === 'DIMENSAO' && item.dimensoes.length > 0) || (item.tipo === 'MIX' && item.mix.length > 0) || (item.tipo === 'URL' && item.redirect.length > 0)">
                                        <option value="DIMENSAO">Dimensão</option>
                                        <option value="MIX">Mix de Produtos</option>
                                        <option value="URL">URL</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="xrow" v-if="item.tipo === 'DIMENSAO'">
                            <h2>
                                Dimensões Selecionadas:
                                <small class="validation-error">{{ validation.firstError("item.dimensoes") }}</small>
                            </h2>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Descrição</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="!item.dimensoes.length">
                                        <td colspan="3" style="text-align: center;">Adicionar Dimensao</td>
                                    </tr>
                                    <tr v-for="(dim, idx) in item.dimensoes">
                                        <td>{{ idx+1 }}</td>
                                        <td>{{ getDescricaoDimensao(dim) }}</td>
                                        <td class="text-right">
                                            <button class="button button-error" title="Remover"
                                                    v-on:click="()=>removeDimensao(idx)">
                                                <strong>&times;</strong>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h2>Adicionar Dimensão:</h2>
                            <div class="col-1-4">
                                <div class="form-group">
                                    <label>Quando:</label>
                                    <label>
                                        <select class="form-control" v-model="dimensao.coluna"
                                                v-on:change="changeDimensao">
                                            <option :value="-1">Selecione</option>
                                            <option v-for="dim in dimensoes" :value="dim.coluna">{{ dim.label }}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>For:</label>
                                    <div class="input-group">
                                        <label>
                                            <select class="form-control" v-model="dimensao.dimensao">
                                                <option v-for="(item, index) in dimensao.dimensoes"
                                                        :key="index"
                                                        :value="item">
                                                    {{ item.desc }} (Cód: {{ item.id }})
                                                </option>
                                            </select>
                                        </label>
                                        <button class="button button-primary input-group-addon-right"
                                                :disabled="!dimensao.dimensao" title="Adicionar"
                                                v-on:click="addDimensao">+
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="xrow" v-if="item.tipo === 'MIX'">
                            <h2>
                                Produtos Selecionados:
                                <small class="validation-error">{{ validation.firstError("item.mix") }}</small>
                            </h2>
                            <Multiselectable :items="produtos" :itemsSelected="item.mix" itemKey="id"
                                             :select-callback="addProdutoMix" :deselect-callback="removeProdutoMix"/>
                        </div>

                        <div class="xrow" v-if="item.tipo === 'URL'">
                            <h2>
                                Conversão de URLs:
                                <small class="validation-error">{{ validation.firstError("item.redirect") }}</small>
                            </h2>
                            <div class="col-1-1">
                                <div class="form-group">
                                    <label for="">URL Origem</label>
                                    <input class="form-control" v-model="item.redirect.url_origem"/>
                                </div>
                            </div>
                            <div class="col-1-1">
                                <div class="form-group">
                                    <label for="">URL Destino</label>
                                    <input class="form-control" v-model="item.redirect.url_destino"/>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                                    v-on:click="handleSubmit">
                                Inserir
                            </button>
                            <button class="button" v-on:click="close">Fechar</button>
                        </div>
                    </CTab>
                </CTabs>
            </div>
        </div>

    </modal>
</template>

<script>
  import SimpleVueValidation from "simple-vue-validator";
  import _find from "lodash/find";
  import _remove from "lodash/remove";
  import Multiselectable from "../../multiselectable";
  import CloseModal from "../../../../components/CloseModal";

  const Validator = SimpleVueValidation.Validator.create({
    templates: {
      required: "Campo de preenchimento obrigatório",
    },
  });

  const initialDimensao = () => ({
    coluna: -1,
    label: "Selecione",
    dimensoes: [],
    dimensao: null,
  });

  export default {
    name: "linkIntegracaoEdit",
    props: [
      "item",
      "dimensoes",
      "produtos",
      "onSubmit",
      "close"
    ],
    data() {
      return {
        dimensao: initialDimensao(),
      };
    },
    computed: {
      getDimensoesItens() {
        if (this.dimensoes[this.dimensao.coluna - 1]) {
          return this.dimensoes[this.dimensao.coluna - 1].dimensoes;
        }
        return [];
      },
    },
    validators: {
      "item.descricao": (value) => Validator.value(value).required(),
      "item.dimensoes, item.tipo": function (dimensoes, tipo) {
        if (tipo !== "DIMENSAO") return;
        return Validator.value(dimensoes).required();
      },
      "item.mix, item.tipo": function (mix, tipo) {
        if (tipo !== "MIX") return;
        return Validator.value(mix).required();
      },
      "item.redirect, item.tipo": function (url, tipo) {
        if (tipo !== "URL") return;
        return Validator.value(url).required();
      },
    },
    methods: {
      handleSubmit() {
        this.$validate().then((success) => {
          if (success) {
            this.onSubmit();
            Validator.reset();
          }
        });
      },
      getDescricaoDimensao(dim) {
        const dimensaoRaiz = _find(this.dimensoes, {coluna: dim.coluna}) || {};
        const subDimensao = _find(dimensaoRaiz.dimensoes || [], {id: dim.dimensao}) || {};
        return (dimensaoRaiz.label || 'Inexistente') + " = " + (subDimensao.desc || dim.dimensao);
      },
      changeDimensao() {
        let dimensaoSelecionada = _find(this.dimensoes, {coluna: this.dimensao.coluna}) || initialDimensao();

        this.dimensao.coluna = dimensaoSelecionada.coluna;
        this.dimensao.dimensoes = dimensaoSelecionada.dimensoes;
        this.dimensao.label = dimensaoSelecionada.label;
        this.dimensao.dimensao = null;
      },
      addDimensao() {
        this.item.dimensoes.push({
          coluna: this.dimensao.coluna,
          dimensao: this.dimensao.dimensao.id
        });
        this.resetDimensaoForm();
      },
      removeDimensao(i) {
        this.item.dimensoes.splice(i, 1);
      },
      resetDimensaoForm() {
        this.dimensao = initialDimensao();
      },
      addProdutoMix(id) {
        this.item.mix.push(id);
      },
      removeProdutoMix(id) {
        const produtos = [...this.item.mix];
        _remove(produtos, (n) => +n === +id);
        this.item.mix = produtos;
      },
    },
    components: {
      Multiselectable,
      CloseModal,
    },
  };
</script>
