<template>
  <div>
    <div>
      <div class="content">
        <div class="content-wrapper">
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="BANNERS">
              <Banners :sistemas="sistemas" />
            </CTab>
            <CTab title="VITRINES">
              <Vitrines :sistemas="sistemas" />
            </CTab>
            <CTab title="LINKS INTEGRADOS">
              <LinkIntegracao :sistemas="sistemas" />
            </CTab>
            <CTab title="GALERIA IMAGENS">
              <Galeria :sistemas="sistemas" />
            </CTab>
          </CTabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banners from './components/Banners/Banners';
import Vitrines from './components/Vitrines/Vitrines';
import LinkIntegracao from './components/LinkIntegracao/LinkIntegracao';
import Galeria from './components/Galeria/Galeria';

export default {
  name: 'marketing',
  components: {
    LinkIntegracao,
    Banners,
    Vitrines,
    Galeria,
  },
  props: [
    'sistemas'
  ],
  data() {
    return {
      descPlural: '',
      descSingular: '',
      currentTab: 'banner',
      loading: true,
      tabs: [
        {
          id: 'banner',
          descricao: 'Banners',
        },
        {
          id: 'vitrine',
          descricao: 'Vitrines',
        },
        {
          id: 'link',
          descricao: 'Links Integrados',
        },
        {
          id: 'galeria',
          descricao: 'Galeria Imagens',
        },
      ],
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_MARKETING'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
      this.loading = false;
    },
    changeTab(id) {
      this.currentTab = id;
    },
  },
  beforeMount() {
    this.loadDescricaoModulo();
  },
};
</script>
