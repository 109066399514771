<template>
    <div>
        <Edit
            :item="currentItem"
            :dimensoes="dimensoes"
            :produtos="produtos"
            :onSubmit="onSubmit"
            :close="closeEdit"
        ></Edit>
        <div class="filters row" style="margin-top:0">
            <div class="col-1-2">
                <span class="filters__search">
                    <input
                        type="text"
                        v-model="term"
                        placeholder="Descrição"
                        v-on:keyup.enter="loadLinks"
                    />
                </span>
                <button class="button button-primary ml-1" v-on:click="loadLinks">
                    Buscar
                </button>
            </div>
            <div class="col-1-2">
                <div class="pull-right">
                    <button v-on:click="openCreate" class="button button-success button-block">
                        <fa-icon icon="plus"/>
                        Criar
                    </button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <datatable :columns="table.columns" :data="items" class="table">
                <template slot-scope="{ row }">
                    <tr>
                        <td>{{ row.id }}</td>
                        <td>{{ row.descricao }}
                            <small>{{ row.url }}</small>
                        </td>
                        <td><img :src="row.qrcode" class="thumbnail thumbnail-small"/></td>
                        <td class="actions">
                            <button type="button" class="button button-primary" v-on:click="openEdit(row)">
                                <fa-icon icon="pencil-alt"/>
                            </button>
                            <button type="button" name="button" v-on:click="deleteConfirm(row.id)"
                                    class="button button-error">
                                <fa-icon icon="trash-alt"/>
                            </button>
                        </td>
                    </tr>
                </template>
            </datatable>
        </div>

        <pagination :data="table.paginator" :limit="2" @pagination-change-page="loadLinks"/>
    </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import Edit from './Edit';

const itemMock = () => {
  return {
    descricao: "",
    dimensoes: [],
    mix: [],
    redirect: {},
    tipo: "DIMENSAO"
  }
}

export default {
  name: 'link-integracao',
  components: {
    Edit,
  },
  data() {
    return {
      loading: false,
      error: false,
      term: '',
      items: [],
      dimensoes: [],
      produtos: [],
      currentItem: {},
      table: {
        filter: '',
        columns: [
          { label: 'ID', field: 'id', sortable: false },
          { label: 'Descrição/Url', field: 'descricao', sortable: false },
          { label: 'QRCode', field: 'qrcode', sortable: false },
          { sortable: false },
        ],
        paginator: {
          current_page: 1,
        },
      },
    };
  },
  methods: {
    loadLinks(page) {
      this.loading = true;
      get(`/admin/marketing/links-integracao?page=${page}&q=${this.term}`)
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    loadDimensoes() {
      this.loading = true;
      get('/admin/lista-dimensoes')
        .then((json) => {
          this.dimensoes = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadProdutos() {
      this.loading = true;
      get('/admin/lista-produtos')
        .then((json) => {
          this.produtos = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate() {
      this.currentItem = itemMock();
      this.$modal.show('linkIntegracaoEdit');
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show('linkIntegracaoEdit');
    },
    closeEdit() {
      this.$modal.hide('linkIntegracaoEdit');
    },
    onSubmit() {
      this.loading = true;
      const { id } = this.currentItem;
      let request = !!id
        ? put(`/admin/marketing/links-integracao/${id}`, this.currentItem)
        : post(`/admin/marketing/links-integracao`, this.currentItem);

      request.then((json) => {
        this.loading = false;
        this.loadLinks(1);
        this.closeEdit();
      })
      .catch((error) => {
        this.loading = false;
      });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/marketing/links-integracao/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadLinks(1);
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
  },
  beforeMount() {
    this.loadLinks(1);
    this.loadDimensoes();
    this.loadProdutos();
  },
};
</script>
